import React from "react";
import { useLocation } from "react-router-dom";
import { CardBody, Col, Row, Input, Button, Form, FormGroup, Label } from "reactstrap";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import withRouterHook from "../../withRouterHook";
import "flatpickr/dist/themes/material_blue.css";
import LoaderComponent from "../../components/LoaderComponent";
import TableContainer from "../../components/common/TableContainer";
import StatusBadge from "../../components/controls/StatusBadge";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";

export type SubscriptionsPageProps = {
    location?: ReturnType<typeof useLocation>;
};

type SubscriptionsPageState = {
    isLoading: boolean;
    tableData: ViewUserSubscriptionProgressInfoDto[] | null;
    filters: {
        userSubscriptionId?: string;
        userSubscriptionUserId?: string;
        fundTradingProgramId?: string;
        userSubscriptionStatus?: SubscriptionStatus;
    };
};

class SubscriptionsPage extends React.Component<SubscriptionsPageProps, SubscriptionsPageState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: SubscriptionsPageState = {
        isLoading: true,
        tableData: null,
        filters: {
            userSubscriptionId: "",
            userSubscriptionUserId: "",
            fundTradingProgramId: "",
            userSubscriptionStatus: SubscriptionStatus.ALL,
        }
    };

    async componentDidMount() {
        await this.fetchSubscriptions();
    }

    // Fetch subscriptions based on filters
    fetchSubscriptions = async () => {
        this.setState({ isLoading: true });
        try {
            const filters = this.state.filters;

            if (filters.userSubscriptionStatus === SubscriptionStatus.ALL) {
                delete filters.userSubscriptionStatus;
            }

            const userSubscriptions = await PortCentralServer.Repo.client.filterUserSubscriptions(filters);

            const sortedSubscriptions = userSubscriptions.data.sort((a, b) => {
                const statusOrder = ["DRAFT", "PAID", "READY", "ACTIVE", "FINISHED", "FAILED"];
                return statusOrder.indexOf(a.userSubscriptionStatus) - statusOrder.indexOf(b.userSubscriptionStatus);
            });

            this.setState({ tableData: sortedSubscriptions });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            this.setState({ isLoading: false });
        }
    };

    // Handle form input change
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            filters: { ...prevState.filters, [name]: value }
        }));
    };

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.fetchSubscriptions();
    };

    render() {
        const { filters } = this.state;

        const columns = [
            {
                Header: "Details",
                Cell: ({ row }: { row: any }) => (
                    <Button
                        color="info"
                        onClick={() => window.location.href = `/subscription-details?userSubscriptionId=${row.original.userSubscriptionId}`}
                    >
                        Details
                    </Button>
                ),
            },
            { Header: "Subscription ID", accessor: "userSubscriptionId" },
            {
                Header: "Status",
                accessor: "userSubscriptionStatus",
                Cell: ({ value }: { value: SubscriptionStatus }) => (
                    <StatusBadge status={value} />
                ),
            },
            { Header: "Fund Trading Program Title", accessor: "fundTradingProgramTitle" },
            { Header: "Account Size", accessor: "fundTradingProgramAccountSize" },
            {
                Header: "Stage Sequence",
                accessor: "fundTradingProgramStageSequence",
                Cell: ({ value }: { value: number | null }) => {
                    if (value === null) {
                        return "No Stage";
                    }
                    const incrementedValue = value + 1; // Increment the value
                    if (incrementedValue > 2) {
                        return "Live";
                    }
                    return incrementedValue;
                },
            }
            ,
            { Header: "User Email", accessor: "userEmail" },
            {
                Header: "Date Created",
                accessor: "userSubscriptionDateCreated",
                Cell: ({ value }: { value: string }) => `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`,
            },
            {
                Header: "Current Profit",
                accessor: "currentStageIndexCurrentProfitPercentage",
                Cell: ({ value }: { value: number }) => `$${Number(value).toFixed(2)}`,
            },
        ];

        return (
            <Layout>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Subscriptions" breadcrumbItem="List" />

                        <Form onSubmit={this.handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="userSubscriptionId">Subscription ID</Label>
                                        <Input
                                            type="text"
                                            name="userSubscriptionId"
                                            value={filters.userSubscriptionId || ""}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="userId">User ID</Label>
                                        <Input
                                            type="text"
                                            name="userId"
                                            value={filters.userSubscriptionUserId || ""}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="fundTradingProgramId">Fund Trading Program ID</Label>
                                        <Input
                                            type="text"
                                            name="fundTradingProgramId"
                                            value={filters.fundTradingProgramId || ""}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="userSubscriptionStatus">Status</Label>
                                        <Input
                                            type="select"
                                            name="userSubscriptionStatus"
                                            defaultValue={filters.userSubscriptionStatus || ""}
                                            onChange={this.handleInputChange}
                                        >
                                            {Object.values(SubscriptionStatus).map((userSubscriptionStatus) => (
                                                <option key={userSubscriptionStatus} value={userSubscriptionStatus}>
                                                    {userSubscriptionStatus}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={2}>
                                    <Button color="primary" type="submit" block>
                                        Apply Filters
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <br />

                        <Row>
                            <Col xl="12">
                                {this.state.isLoading ? (
                                    <LoaderComponent />
                                ) : (
                                    <Row>
                                        <CardBody>
                                            <div className="mb-4 h4 card-title">User Subscriptions</div>
                                            <TableContainer
                                                columns={columns}
                                                data={this.state.tableData || []} // Ensure to handle null data
                                                isGlobalFilter={false}
                                                isAddOptions={false}
                                                customPageSize={20}
                                            />
                                        </CardBody>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouterHook(SubscriptionsPage);
