import { destroyCookie, parseCookies, setCookie } from 'nookies';

export const COOKIE_EXPIRATION_TIME = 60 * 60 * 24 // 1 day
export const TOKEN_COOKIE = 'meta.token'
export const REFRESH_TOKEN_COOKIE = 'meta.refreshToken'


type CreateSessionCookiesParams = {
  token?: string;
  refreshToken?: string;
};

export class AuthCookieManager {
  static createSessionCookies(params: CreateSessionCookiesParams) {
    const { token, refreshToken } = params;

    if (token) {
      setCookie(null, TOKEN_COOKIE, token, {
        maxAge: COOKIE_EXPIRATION_TIME,
        path: '/',
      });
    }

    if (refreshToken) {
      setCookie(null, REFRESH_TOKEN_COOKIE, refreshToken, {
        maxAge: COOKIE_EXPIRATION_TIME,
        path: '/',
      });
    }
  }

  static removeSessionCookies() {
    destroyCookie(null, TOKEN_COOKIE, { path: '/', });
    destroyCookie(null, REFRESH_TOKEN_COOKIE, { path: '/', });
  }

  static getToken(): string | undefined {
    const cookies = parseCookies();
    return cookies[TOKEN_COOKIE];
  }

  static getRefreshToken(): string | undefined {
    const cookies = parseCookies();
    return cookies[REFRESH_TOKEN_COOKIE];
  }
}
