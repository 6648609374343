import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Import scss
import "./assets/scss/theme.scss";

// Pages
import { HomePage } from "./pages/HomePage";
import { NotificationsPage } from "./pages/NotificationsPage";
import { SomePage } from "./pages/SomePage";
import AcceptInvitationPage from "./pages/auth/AcceptInvitationPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import RequestPasswordResetPage from "./pages/auth/RequestPasswordResetPage";
import SignInPage from "./pages/auth/SignInPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfileSettingsPage from "./pages/profile/ProfileSettingsPage";
import CronJobsPage from "./pages/system/CronJobsPage";
import SystemLogPage from "./pages/system/SystemLogPage";
import SubscriptionsPage from "./pages/subscription/SubscriptionsPage";
import SubscriptionDetailsPage from "./pages/subscription-details/SubscriptionDetailsPage";
import SubscriptionProgressPage from "./pages/subscription-progress/SubscriptionProgressPage";

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="forgot-password" element={<RequestPasswordResetPage />} />
          <Route path="reset-password" element={<PasswordResetPage />} />
          <Route path="invitation" element={<AcceptInvitationPage />} />

          <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />

          <Route path="/subscriptions" element={<ProtectedRoute><SubscriptionsPage /></ProtectedRoute>} />

          <Route path="/subscription-details" element={<ProtectedRoute><SubscriptionDetailsPage /></ProtectedRoute>} />

          <Route path="/subscription-progress/:userSubscriptionId/:userSubscriptionStageIndexId/:userId" element={<ProtectedRoute><SubscriptionProgressPage /></ProtectedRoute>} />

          {/* System Pages */}
          <Route path="system/logs" element={<ProtectedRoute><SystemLogPage /></ProtectedRoute>} />
          <Route path="system/cron-jobs" element={<ProtectedRoute><CronJobsPage /></ProtectedRoute>} />
          {/* System Pages */}

          {/* Profile Pages */}
          <Route path="profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="profile/settings" element={<ProtectedRoute><ProfileSettingsPage /></ProtectedRoute>} />
          {/* Profile Pages */}

          {/* Common Secured Pages */}
          <Route path="notifications" element={<ProtectedRoute><NotificationsPage /></ProtectedRoute>} />
          {/* Common Secured Pages */}

          <Route path="some" element={<ProtectedRoute><SomePage /></ProtectedRoute>} />

        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
