import React from "react";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { TaskResponseDto } from "../../lib/drivers/dto/system/TaskResponseDto";

export type CronJobsPageProps = {};

type CronJobsPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: TaskResponseDto[];
};

class CronJobsPage extends React.Component<CronJobsPageProps, CronJobsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: CronJobsPageState = {
    isLoading: false,
    startDate: new Date(),
    columns: [],
    list: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const dataFilterLoggingResponseList = await this.loadData();

    await this.setState((prevState) => {
      return { list: dataFilterLoggingResponseList };
    });
  };

  componentDidUpdate = async (prevProps: CronJobsPageProps, prevState: CronJobsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleSelect(e: React.ChangeEvent<HTMLSelectElement>): void {
    // let search = e.target.value;
    // if (search === "All") {
    //   setOrder(orders)
    // } else {
    //   handleSearchData({ setState: setOrder, data: orders, item: search })
    // }
  }
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };

  setStartDate = async (date: Date) => {
    await this.setState((prevState) => {
      return {
        startDate: date
      };
    });
  };
  // #endregion

  // #region Functions
  private async loadData(): Promise<TaskResponseDto[]> {
    const result = await PortCentralServer.Repo.client.getTaskList();

    return result;
  }
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="System" breadcrumbItem="Logs" />
              <Row>
                <Col xl={12}>
                  <div className="table-responsive">
                    <Table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Next Execution</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.list || []).map((item: TaskResponseDto, key: number) => (
                          <tr key={key}>
                            <td>{item.name}</td>
                            <td>{moment(item.nextExecution).format("YYYY-MM-DD HH:mm:ss")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(CronJobsPage);
