import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Form } from "reactstrap";

import { AppContext } from "../context/AppProvider";

// Props & State
export type ProfileMenuProps = {};

type ProfileMenuState = {
  menu: boolean;
};

export class ProfileMenu extends React.Component<ProfileMenuProps, ProfileMenuState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  username: String = "Admin";

  state: Readonly<ProfileMenuState> = {
    menu: false
  };

  // #region State Setters
  setMenu = async (value: boolean) => {
    await this.setState((prevState) => {
      return { menu: value };
    });
  };
  // #endregion

  // #region Handlers
  handleSubmitSignOut = async (e: React.FormEvent<HTMLFormElement>) => {
    await this.context.signOut();
  };
  // #endrgion

  render() {
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={() => this.setMenu(!this.state.menu)} className="d-inline-block">
          <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
            {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}
            <span className="d-none d-xl-inline-block ms-2 me-1">{this.username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Profile
            </DropdownItem>
            <DropdownItem tag="a" href="/profile/settings">
              <span className="badge bg-success float-end">11</span>
              <i className="bx bx-wrench font-size-16 align-middle me-1" />
              Settings
            </DropdownItem>
            <div className="dropdown-divider" />
            <Form onSubmit={(e) => this.handleSubmitSignOut(e)}>
              <Button type="submit" className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>Logout</span>
              </Button>
            </Form>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
