import React from "react";

interface StatusBadgeProps {
    status: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
    const getStatusColor = (status: string) => {
        switch (status) {
            case "ACTIVE":
                return "text-success";  // Green for active
            case "FAILED":
                return "text-danger";   // Red for failed
            case "FINISHED":
                return "text-primary";  // Blue for finished
            case "DRAFT":
                return "text-warning";  // Yellow for draft
            case "PAID":
                return "text-info";     // Light blue for paid
            default:
                return "text-muted";    // Grey for other statuses
        }
    };

    return <span className={getStatusColor(status)}>{status}</span>;
};

export default StatusBadge;
