import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import withRouterHook from "../../withRouterHook";
import "flatpickr/dist/themes/material_blue.css";
import { UserSubscriptionStageIndexDto } from "../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDto";
import ReactApexChart from "react-apexcharts";

import { UserSubscriptionStageIndexDayDto } from "../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDayDto";
import LoaderComponent from "../../components/LoaderComponent";
import TableContainer from "../../components/common/TableContainer";
import getChartColorsArray from "../../components/common/ChartsDynamicColor";
import { ApexOptions } from "apexcharts";

export type SubscriptionProgressPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type ReportItem = {
  title: string;
  iconClass: string;
  description: string;
};

type SeriesItem = {
  name: string;
  data: number[];
};

type SubscriptionProgressPageState = {
  isLoading: boolean;
  userSubscriptionStagesData: UserSubscriptionStageIndexDto[];
  userSubscriptionStageIndexData: UserSubscriptionStageIndexDto;
  userSubscriptionStageIndexDaysData: UserSubscriptionStageIndexDayDto[];
  report: ReportItem[];
  tableData: Object[];
  chartType: string;
  selectedStageId: string | null
};

class SubscriptionProgressPage extends React.Component<SubscriptionProgressPageProps, SubscriptionProgressPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SubscriptionProgressPageState = {
    isLoading: true,
    userSubscriptionStagesData: [],
    userSubscriptionStageIndexData: null,
    userSubscriptionStageIndexDaysData: [],
    report: [],
    tableData: [],
    chartType: null,
    selectedStageId: null
  };

  // #region React Events
  componentDidMount = async () => {
    const paths = this.props.location.pathname.split("/");

    const userSubscriptionId = paths[paths.length - 3];
    const userSubscriptionStageIndexId = paths[paths.length - 2];
    const userId = paths[paths.length - 1];

    const userSubscriptionStagesData = await PortCentralServer.Repo.client.getUserSubscriptionStagesData(
      userSubscriptionId
    );

    if (userSubscriptionStagesData) {
      this.setState({ userSubscriptionStagesData });
    }

    // Fetch and set initial data
    await this.fetchData(userId, userSubscriptionId, userSubscriptionStageIndexId);
  };

  fetchData = async (userId: string, userSubscriptionId: string, userSubscriptionStageIndexId: string) => {
    const userSubscriptionStageIndexData = await PortCentralServer.Repo.client.getUserSubscriptionStageIndex(
      userId,
      userSubscriptionId,
      userSubscriptionStageIndexId
    );

    const userSubscriptionStageIndexDaysData = await PortCentralServer.Repo.client.getUserSubscriptionStageIndexDays(
      userId,
      userSubscriptionId,
      userSubscriptionStageIndexId
    );

    if (userSubscriptionStageIndexData && userSubscriptionStageIndexDaysData) {
      const report = [
        { title: "Total Orders", iconClass: "bx-copy-alt", description: userSubscriptionStageIndexData.totalOrders.toLocaleString() },
        { title: "Total Volume", iconClass: "bx-copy-alt", description: `${Number(userSubscriptionStageIndexData.tradingVolume).toFixed(6).toLocaleString()} USDT` },
        {
          title: "Total PNL",
          iconClass: "bx-copy-alt",
          description: `${Number(userSubscriptionStageIndexData.totalRealizedPnl).toFixed(6).toLocaleString()} USDT`,
        },
        // {
        //   title: "Total ROI",
        //   iconClass: "bx-copy-alt",
        //   description: `${Number(userSubscriptionStageIndexData.totalRoi).toFixed(2).toLocaleString()} %`,
        // },
        {
          title: "Percentage Profit",
          iconClass: "bx-copy-alt",
          description: `${Number(userSubscriptionStageIndexData.currentProfitPercentage).toFixed(2).toLocaleString()} %`,
        },
      ];

      const tableData = userSubscriptionStageIndexDaysData.map((item, index) => ({
        date: `${item.year}-${item.month}-${item.day}`,
        totalOrders: item.totalOrders,
        tradingVolume: Number(item.tradingVolume).toFixed(6).toString(),
        totalRealizedPnl: Number(item.totalRealizedPnl).toFixed(6).toString(),
        totalRoi: Number(item.totalRoi).toFixed(2).toString(),
        currentProfitPercentage: Number(item.currentProfitPercentage).toFixed(2).toString(),
        view: "View Details", // Placeholder for button or other action
      }));

      this.setState(() => {
        return {
          userSubscriptionStageIndexData: userSubscriptionStageIndexData,
          userSubscriptionStageIndexDaysData,
          report,
          tableData,
          isLoading: false
        };
      });
    } else {
      await this.setState(() => {
        return {
          isLoading: false
        };
      });
    }
  };

  handleChartTypeClick = async (e: React.FormEvent<HTMLButtonElement>, chartType: string) => {
    e.preventDefault();
    this.setState({ chartType });
  };

  ChartContent = () => {
    const { userSubscriptionStageIndexDaysData, chartType } = this.state;

    // Get dynamic chart colors
    const apexCardUserChartColors = getChartColorsArray('["--bs-primary", "--bs-warning"]');

    // Prepare data based on the selected chart type
    let title = "";
    let data: SeriesItem[] = [];
    let categories: string[] = [];

    // Map data for the chart based on the selected chart type
    if (userSubscriptionStageIndexDaysData.length > 0) {
      categories = userSubscriptionStageIndexDaysData.map(
        (item) => `${item.year}-${item.month}-${item.day}`
      );

      if (chartType === "orders" || !chartType) {
        title = "Total Orders";
        data = [
          {
            name: "Orders",
            data: userSubscriptionStageIndexDaysData.map((item) => item.totalOrders),
          },
        ];
      } else if (chartType === "volume") {
        title = "Total Volume (USDT)";
        data = [
          {
            name: "Volume",
            data: userSubscriptionStageIndexDaysData.map((item) => Number(Number(item.tradingVolume).toFixed(6))),
          },
        ];
      } else if (chartType === "pnl") {
        title = "Total PNL (USDT)";
        data = [
          {
            name: "PNL",
            data: userSubscriptionStageIndexDaysData.map((item) => Number(Number(item.totalRealizedPnl).toFixed(6))),
          },
        ];
      }
    }

    // Chart options
    let options: ApexOptions = {
      chart: {
        height: 350,
        type: "area",
        toolbar: { show: false },
      },
      colors: apexCardUserChartColors,
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 2 },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      xaxis: {
        categories,
      },
      markers: {
        size: 3,
        strokeWidth: 3,
        hover: { size: 4, sizeOffset: 2 },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
    };

    return (
      <CardBody>
        <div className="d-flex flex-wrap">
          <h5 className="card-title me-2">{title}</h5>
        </div>
        <hr className="mb-4" />
        <div id="area-chart" dir="ltr">
          <ReactApexChart
            options={options}
            series={data}
            type="area"
            height={350}
            className="apex-charts"
          />
        </div>
      </CardBody>
    )
  };

  StageButtons = () => {
    const { userSubscriptionStagesData, userSubscriptionStageIndexData } = this.state;

    return (
      <Row>
        <div className="row justify-content-center">
          {userSubscriptionStagesData.map((stage, index) => {
            const stageLabel = index === 0 ? "Stage 1" : index === 1 ? "Stage 2" : "Live";

            const isCurrent = stage.userSubscriptionStageIndexId === userSubscriptionStageIndexData.userSubscriptionStageIndexId;
            const buttonClass = `w-xs btn ${isCurrent ? 'btn-success' : 'btn-primary'}`;
            return (
              <div className="col-md-6 col-lg-4 text-center" key={stage.userSubscriptionStageIndexId}>
                <button
                  type="button"
                  className={buttonClass}
                  onClick={() => this.handleStageClick(stage.userSubscriptionStageIndexId)}
                >
                  {stageLabel}
                </button>
              </div>
            );
          })}
        </div>
      </Row>
    );
  };

  handleStageClick = async (fundTradingProgramStageId: string) => {
    this.setState({ selectedStageId: fundTradingProgramStageId, isLoading: true });

    // Fetch data for the selected stage
    const paths = this.props.location.pathname.split("/");
    const userSubscriptionId = paths[paths.length - 3];
    const userId = paths[paths.length - 1];

    // Fetch and set data based on selected stage
    await this.fetchData(userId, userSubscriptionId, fundTradingProgramStageId);
  };

  render() {
    const columns = [
      {
        Header: "Date",
        accessor: "date",
        filterable: false,
        disableFilters: true,
        Cell: ({ value }: { value: string }) => {
          return value || "N/A";
        },
      },
      {
        Header: "Total Orders",
        accessor: "totalOrders",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Trading Volume (USDT)",
        accessor: "tradingVolume",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Total PNL (USDT)",
        accessor: "totalRealizedPnl",
        filterable: false,
        disableFilters: true,
      },
      // {
      //   Header: "Total ROI (%)",
      //   accessor: "totalRoi",
      //   filterable: false,
      //   disableFilters: true,
      // },
      {
        Header: "Percentage Profit %",
        accessor: "currentProfitPercentage",
        filterable: false,
        disableFilters: true,
      },
    ];

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Account" breadcrumbItem="Progress" />
              <Row className="">
                <Col xl="12">
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : <>
                    <this.StageButtons />
                    <br />
                    <Row className="justify-content-center">
                      {/* Reports Render */}
                      {this.state.report.map((report, key) => (
                        <Col className="" md="3" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="fw-medium">
                                    {report.title}
                                  </p>
                                  <h4 className="mb-0">{report.description}</h4>
                                </div>
                                {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                      className={
                                        "bx " + report.iconClass + " font-size-24"
                                      }
                                    ></i>
                                  </span>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-4 text-center">
                          <div className="btn-group btn-group-example mb-3" role="group">
                            <button type="button" className="w-xs btn btn-primary" onClick={(e) => this.handleChartTypeClick(e, 'orders')}>
                              {/* <i className="mdi mdi-thumb-down" /> */}
                              Total Orders
                            </button>
                            <button type="button" className="w-xs btn btn-primary" onClick={(e) => this.handleChartTypeClick(e, 'volume')}>
                              {/* <i className="mdi mdi-thumb-up" /> */}
                              Total Volume
                            </button>
                            <button type="button" className="w-xs btn btn-primary" onClick={(e) => this.handleChartTypeClick(e, 'pnl')}>
                              {/* <i className="mdi mdi-thumb-down" /> */}
                              Total PNL
                            </button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Card>
                        <this.ChartContent />
                      </Card>
                    </Row>
                  </>}
                </Col>
              </Row>
              <Row>
                <CardBody>
                  <div className="mb-4 h4 card-title">Latest Progress</div>
                  <TableContainer
                    columns={columns}
                    data={this.state.tableData}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6} className={undefined} isAddUserList={undefined} handleOrderClicks={undefined} handleUserClick={undefined} handleCustomerClick={undefined} isAddCustList={undefined} customPageSizeOptions={undefined} />
                </CardBody>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SubscriptionProgressPage);
