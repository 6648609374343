import React, { useEffect, FC } from "react";
import { Spinner } from "reactstrap";

interface LoaderComponentProps {
  setLoading?: (loading: boolean) => void;
}

const LoaderComponent: FC<LoaderComponentProps> = ({ setLoading }) => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [setLoading]);

  return (
    <React.Fragment>
      <Spinner color="primary" className='position-absolute top-50 start-50' />
    </React.Fragment>
  );
}

export default LoaderComponent;
