import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import LoaderComponent from "../components/LoaderComponent";

export type HomePageProps = {};

type HomePageState = {
  isLoading: boolean;
};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  state: HomePageState = {
    isLoading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    // ...
    await this.setState((prevState) => {
      return {
        isLoading: false
      };
    });
  };

  componentDidUpdate = async (prevProps: HomePageProps, prevState: HomePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

              <div className="row">
                <div className="col-md-4">
                  <div className="mini-stats-wid card">
                    <div className="card-body">{this.state.isLoading ? <LoaderComponent /> : <>This is dashboard</>}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}
