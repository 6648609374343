
export enum CentralServerPathParamKind {
    uid = "{uid}",
    tournamentId = "{tournamentId}",
    exchangePartnerId = "{exchangePartnerId}",
    year = "{year}",
    month = "{month}",
    fundTradingProgramId = "{fundTradingProgramId}",
    userSubscriptionId= "{userSubscriptionId}",
    userSubscriptionPayoutId= "{userSubscriptionPayoutId}",
    userSubscriptionStageIndexId= "{userSubscriptionStageIndexId}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // auth
    auth_sign_in = "auth/sign-in",
    auth_request_password_reset = "auth/request-password-reset",
    auth_reset_password = "auth/reset-password",
    auth_accept_invitation = "auth/accept-invitation",

    // profile
    profile_change_password = "profile/change-password",
    user_badge = "profile/badge",

    // system
    logs_filter = "logs/filter",
    tasks = "tasks",

    //incoming
    user_subscription_activate = "incoming/subscriptions/set-ready",
    user_subscription_stage_activate = "incoming/subscriptions/indexes/set-ready",
    user_subscription_deactivate = "incoming/subscriptions/set-disabled",

    // user
    users_filter = "users/filter",
    users_invite = "users/invite",

    // userSubscription
    user_subscriptions_view_filter = "user-subscriptions/infos/filter",
    user_subscriptions_filter = "user-subscriptions/filter",
    user_subscriptions_stages = "user-subscriptions/{userSubscriptionId}/stage-indexes",
    user_subscriptions_stage_index = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/{uid}",
    user_subscriptions_stage_index_days = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/days/{uid}",
    user_subscriptions_stage_index_day_orders = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/orders/filter/{uid}",
    user_subscriptions_credentials = "user-subscriptions/credentials/{userSubscriptionId}/{uid}",
    user_subscription_payout = "user-subscriptions/payouts/{userSubscriptionPayoutId}/{uid}",
    user_subscriptions_payout_complete = "user-subscriptions/payouts/complete",
    user_subscriptions_billings = "user-subscriptions/purchases/filter",
    user_subscriptions_payouts = "user-subscriptions/payouts/filter",
}
