import React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppProvider";
import { ProfileMenu } from ".//ProfileMenu";

// import images
import logo from "../assets/images/logo-light.svg";

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  search: boolean;
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state = {
    megaMenu: false,
    search: false
  };

  // #region State Setters
  setMegaMenu(value: boolean) {
    this.setState((prevState) => {
      return { megaMenu: value };
    });
  }

  setSearch(value: boolean) {
    this.setState((prevState) => {
      return { search: value };
    });
  }
  // #endregion

  // #region handlers
  handleToggleLeftMenu() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collapsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  handleSubmitSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  // #endregion

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                {/* <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </Link> */}

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={(e) => {
                  this.handleToggleLeftMenu();
                }}
                id="vertical-menu-btn"
                className="btn btn-sm px-3 font-size-16 header-item "
                title="toggle"
              >
                <i className="fa fa-fw fa-bars" />
              </button>

              {/* <form className="app-search d-none d-lg-block" onSubmit={(e) => this.handleSubmitSearch(e)}>
                <div className="position-relative">
                  <input type="text" className="form-control" placeholder="Search..." />
                  <span className="bx bx-search-alt" />
                </div>
              </form> */}
            </div>
            <div className="d-flex">
              {/* <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setSearch(!this.state.search);
                  }}
                  type="button"
                  id="page-header-search-dropdown"
                  className="btn header-item noti-icon"
                  title="setsearch"
                >
                  <i className="mdi mdi-magnify" />
                </button>
                <div
                  className={this.state.search ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"}
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" title="search">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}

              {/* <NotificationDropdown /> */}
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
