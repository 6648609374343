import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { AcceptInvitationRequestDto } from './dto/auth/AcceptInvitationRequestDto';
import { AcceptInvitationResponseDto } from './dto/auth/AcceptInvitationResponseDto';
import { ChangePasswordRequestDto } from './dto/auth/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/auth/ChangePasswordResponseDto';
import { CreatePasswordResetDto } from "./dto/auth/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/auth/CreatePasswordResetResponseDto';
import { PasswordResetRequestDto } from "./dto/auth/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/auth/PasswordResetResponseDto';
import { SignInDto } from "./dto/auth/SignInDto";
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { LogRequestDto } from './dto/system/LogRequestDto';
import { LogResponseDto } from './dto/system/LogResponseDto';
import { TaskResponseDto } from './dto/system/TaskResponseDto';
import { FilterUserSubscriptionResponseDto } from './dto/subscriptions/FilterUserSubscriptionResponseDto';
import { FilterViewUserSubscriptionProgressInfoRequestDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoRequestDto';
import { FilterViewUserSubscriptionProgressInfoResponseDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoResponseDto';
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { UserSubscriptionStageIndexDayDto } from './dto/subscriptions/UserSubscriptionStageIndexDayDto';
import { FilterUserSubscriptionStageIndexDayOrderDto } from './dto/subscriptions/UserSubscriptionStageIndexDayOrderDto';
import { UserSubscriptionStageIndexDto } from './dto/subscriptions/UserSubscriptionStageIndexDto';
import { UserBadgeDto } from './dto/subscriptions/UserBadgeDto';
import { CredentialsDto } from './dto/subscriptions/CredentialsDto';
import { FilterBillingsDto } from './dto/subscriptions/FilterBillingsDto';
import { FilterPayoutsDto, PayoutsDto } from './dto/subscriptions/FilterPayoutsDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Auth Endpoints
    async authSignIn(model: SignInDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authAcceptInvitation(model: AcceptInvitationRequestDto): Promise<AcceptInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_accept_invitation.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AcceptInvitationResponseDto = data as AcceptInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region userSubscriptions Endpoints
    async filterUserSubscriptions(model: FilterViewUserSubscriptionProgressInfoRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_view_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterSubscriptions(model: FilterViewUserSubscriptionProgressInfoResponseDto): Promise<FilterUserSubscriptionResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterUserSubscriptionResponseDto = data as FilterUserSubscriptionResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateUserSubscription(userSubscriptionId: string, credentials: Object): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_activate.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, {userSubscriptionId, credentials}, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateUserSubscriptionIndex(userSubscriptionId: string, fundTradingProgramStageId: string, dateStart: Date, credentials: Object): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_stage_activate.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, {userSubscriptionId, fundTradingProgramStageId, dateStart, credentials}, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async deactivateUserSubscription(userSubscriptionId: string, message: string): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_deactivate.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, {userSubscriptionId, message}, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getPayouts(): Promise<FilterPayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getBillings(): Promise<FilterBillingsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_billings.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getCredentials(userId: string, userSubscriptionId: string): Promise<CredentialsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_credentials.toString(), [
                new UrlParamPair(CentralServerPathParamKind.uid, userId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserPayout(userId: string, userSubscriptionPayoutId: string): Promise<PayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_payout.toString(), [
                new UrlParamPair(CentralServerPathParamKind.uid, userId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionPayoutId, userSubscriptionPayoutId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async payoutSubscription(userSubscriptionPayoutId: string): Promise<PayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payout_complete.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, { userSubscriptionPayoutId }, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserBadge(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_badge.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStagesData(userSubscriptionId: string): Promise<UserSubscriptionStageIndexDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stages.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            
            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndex(userId: string, userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index.toString(), [
                new UrlParamPair(CentralServerPathParamKind.uid, userId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDays(userId: string, userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_days.toString(), [
                new UrlParamPair(CentralServerPathParamKind.uid, userId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDayOrders(userId: string, userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<FilterUserSubscriptionStageIndexDayOrderDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_day_orders.toString(), [
                new UrlParamPair(CentralServerPathParamKind.uid, userId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion


    // #region Profile Endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region System Endpoints
    async filterLogList(model: LogRequestDto): Promise<LogResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.logs_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: LogResponseDto = data as LogResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getTaskList(): Promise<TaskResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tasks.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: TaskResponseDto[] = data as TaskResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}