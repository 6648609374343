import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

export type BreadcrumbsProps = {
  title: String;
  breadcrumbItem: String;
};

type BreadcrumbsState = {};

export class Breadcrumbs extends React.Component<BreadcrumbsProps, BreadcrumbsState> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{this.props.breadcrumbItem}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="#">{this.props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active={true}>
                    <Link to="#">{this.props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
