import React, { useEffect, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const context = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (context) {
      // If context is available, update the loading state based on the context's loading state
      setIsLoading(context.loadingAuthData);
    } else {
      // Handle the case where context is not available
      setIsLoading(false); // or maintain true if you want to show loading until context is available
    }
  }, [context]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!context) {
    return <div>Context not available</div>;
  }

  if (!context.isLoggedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};
