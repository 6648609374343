import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

export type SidebarContentProps = {};

type SidebarContentState = {};

export class SidebarContent extends React.Component<SidebarContentProps, SidebarContentState> {
  ref: React.RefObject<SimpleBar>;

  constructor(props: SidebarContentProps) {
    super(props);

    this.ref = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-file"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="/subscriptions">
                  <i className="bx bx-file"></i>
                  <span>Subscriptions</span>
                </Link>
              </li>
              <li>
                <Link to="/purchases">
                  <i className="bx bx-dollar"></i>
                  <span>Purchases</span>
                </Link>
              </li>
              <li>
                <Link to="/payouts">
                  <i className="bx bx-dollar"></i>
                  <span>Pyouts</span>
                </Link>
              </li>

              {/* <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Users</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/user/list">Users</Link>
                  </li>
                  <li>
                    <Link to="/user/invitations">Invitations</Link>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}
