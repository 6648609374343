import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, Alert } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import withRouterHook from "../../withRouterHook";
import { AppContext } from "../../context/AppProvider";

import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-light.svg";

export type SignInPageProps = {
  params?: ReturnType<typeof useParams>;
  navigate?: ReturnType<typeof useNavigate>;
};

type SignInPageState = {
  here: boolean;
  valid: boolean;
  successMessage: string;
  errorMessage: string;
};

class SignInPage extends React.Component<SignInPageProps, SignInPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SignInPageState = {
    here: true,
    valid: false,
    successMessage: null,
    errorMessage: null
  };

  refEmail: React.RefObject<HTMLInputElement>;
  refPassword: React.RefObject<HTMLInputElement>;
  refCaptcha: React.RefObject<ReCAPTCHA>;

  constructor(props: SignInPageProps) {
    super(props);

    this.refEmail = React.createRef();
    this.refPassword = React.createRef();
    this.refCaptcha = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: SignInPageProps, prevState: SignInPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const recaptchaToken = this.refCaptcha.current.getValue();
    const widgetId = this.refCaptcha.current.getWidgetId();

    if (!recaptchaToken) {
      this.context.notifyError("CAPTCHA not verified");
      return;
    }

    const email = this.refEmail.current.value;
    const password = this.refPassword.current.value;

    await this.context.signIn({ email, password, recaptchaToken: recaptchaToken });

    if (this.props.navigate) {
      this.props.navigate("/");
    }
  }

  handleReCaptchaChange = async (token: string) => {
    await this.setState((prevState) => {
      return { valid: true };
    });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary-subtle">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to BoneX Fund Trader.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.state.errorMessage ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.state.errorMessage}
                        </Alert>
                      ) : null}

                      {this.state.successMessage ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.state.successMessage}
                        </Alert>
                      ) : null}

                      <Form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input name="email" className="form-control" type="email" innerRef={this.refEmail} />
                          {/* {validation.touched.email && validation.errors.email ? <FormFeedback type="invalid">{validation.errors.email}</FormFeedback> : null} */}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input name="password" type="password" innerRef={this.refPassword} />
                          {/* {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null} */}
                        </div>

                        {/* <div className="form-check">
                          <Input type="checkbox" className="form-check-input" id="customControlInline" />
                          <label className="form-check-label" htmlFor="customControlInline">
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-3 d-grid">
                          <ReCAPTCHA ref={this.refCaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleReCaptchaChange} />
                        </div>

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="submit" disabled={!this.state.valid}>
                            Sign In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} BoneX Fund Trader</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SignInPage);
