import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface PagerComponentProps {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

class PagerComponent extends React.Component<PagerComponentProps> {
  createPaginationLink(page: number) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', page.toString());
    currentUrlParams.set('pageSize', this.props.pageSize.toString());
    return `?${currentUrlParams.toString()}`;
  }

  renderPaginationItems() {
    const { currentPage, pageSize, totalItems } = this.props;
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxPageItems = 5;
    let startPage = 0, endPage = 0;

    // Logic to determine startPage and endPage
    if (totalPages <= maxPageItems) {
        // Less than maxPageItems total pages so show all
        startPage = 1;
        endPage = totalPages;
      } else {
        // More than maxPageItems total pages so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPageItems / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPageItems / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // Current page near the start
          startPage = 1;
          endPage = maxPageItems;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // Current page near the end
          startPage = totalPages - maxPageItems + 1;
          endPage = totalPages;
        } else {
          // Current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

    let paginationItems = [];
    if (currentPage > 1) {
      paginationItems.push(
        <PaginationItem key="first">
          <PaginationLink href={this.createPaginationLink(1)}>First</PaginationLink>
        </PaginationItem>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem active={i === currentPage} key={i}>
          <PaginationLink href={this.createPaginationLink(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    if (currentPage < totalPages) {
      paginationItems.push(
        <PaginationItem key="last">
          <PaginationLink href={this.createPaginationLink(totalPages)}>Last</PaginationLink>
        </PaginationItem>
      );
    }

    return paginationItems;
  }

  render() {
    return (
      <Pagination>
        {this.renderPaginationItems()}
      </Pagination>
    );
  }
}

export default PagerComponent;
